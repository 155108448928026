import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }



  public set(key: string, data: any){
    localStorage.setItem(key, JSON.stringify(data));
  }

  public get<T>(key: string){
    const val = localStorage.getItem(key);
    if(val)
      return JSON.parse(val) as T;

    return undefined;
  }

  public clear(){ localStorage.clear(); }

  public remove(key: string){ localStorage.removeItem(key); }

  public exists(key: string){
    return localStorage.getItem(key) !== undefined;
  }

}
