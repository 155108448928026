import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {HomeComponent} from './Pages/home/home.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LoginComponent} from './Pages/login/login.component';
import {environment} from "../environments/environment";
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import {AppDropdownComponent} from './components/app-dropdown/app-dropdown.component';
import {
  AdminClient,
  API_BASE_URL, CommandCenterClient,
  OrganisationClient, PresenceClient, RsldcClient,
  StreamClient,
  VideoGalleryClient
} from "./schematics/openApiClients";
import {FormsModule} from "@angular/forms";
import {StorageService} from "./schematics/storage.service";
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import { VideoComponent } from './components/video/video.component';
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from "./schematics/timeout.interceptor";
import {NgOptimizedImage} from "@angular/common";
import {CommonHttpInterceptor, PublicModeHttpInterceptor} from "./schematics/http.interceptor";
import {CommandCenterComponent} from "./Pages/command-center/command-center.component";
import {CamMetadataComponent} from "./components/video/cam-metadata/cam-metadata.component";
import {DropdownBarComponent} from "./dropdown-bar/dropdown-bar.component";
import {LiveAttendanceComponent} from "./Pages/live-attendance/live-attendance.component";
import {PaginationComponent} from "./components/pagination/pagination.component";
import {RsldcApiService} from "./schematics/rsldc-api.service";

const get_server_url = () => localStorage.getItem('server-url') ?? environment.apiService.url;
const protected_urls = () => [
  environment.apiService.url,
  environment.auth0.domain,
  ...environment.apiService.servers
].map(m => `${m}/*`)


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    LiveAttendanceComponent,
    AppDropdownComponent,
    CommandCenterComponent,
    VideoComponent,
    DropdownBarComponent,
    CamMetadataComponent,
    PaginationComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    AuthModule.forRoot({
      ...environment.auth0,
      authorizationParams: {
        redirect_uri: document.location.origin + '/home'
      },
      httpInterceptor: {
        allowedList: protected_urls(),
      },
    }),
    NgOptimizedImage

  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: CommonHttpInterceptor, multi: true},
    {provide: DEFAULT_TIMEOUT, useValue: 120000},
    {
      provide: API_BASE_URL,
      useValue: get_server_url()
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: environment.publicMode ? PublicModeHttpInterceptor : AuthHttpInterceptor,
      multi: true,
    },
    OrganisationClient,
    VideoGalleryClient,
    RsldcClient,
    StreamClient,
    AdminClient,
    CommandCenterClient,
    StorageService,
    PresenceClient,
    RsldcApiService
  ],
  exports: [
    VideoComponent,
    AppDropdownComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
