import {Component, EventEmitter, Input, Output} from '@angular/core';
import {icons} from "../../common/helpers";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  readonly icons = icons;
  @Input() page: number = 1;
  @Input() totalPages: number = 5;
  @Input() classList: string = 'is-info'
  @Output() onPageChange = new EventEmitter<number>();

  async paginate(dir: number){
    if(dir === 1){
      this.page++;
      if(this.page > this.totalPages)
        this.page = 1;
    }

    if(dir === -1){
      this.page--;
      if(this.page < 1)
        this.page = this.totalPages;
    }

    this.onPageChange.emit(this.page);
  }
}
