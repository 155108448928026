import {Component, Input} from '@angular/core';
import {Camera} from "../../../schematics/openApiClients";

@Component({
  selector: 'app-cam-metadata',
  templateUrl: './cam-metadata.component.html',
  styleUrl: './cam-metadata.component.scss'
})
export class CamMetadataComponent {
  @Input() camera: Partial<Camera> = {};
}
