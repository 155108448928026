<div class="root is-align-self-stretch ">
  <div class="columns is-align-items-stretch" style="min-height: 500px">
    <div class="column"></div>
    <div class="column">
      <div class="login-wrapper">
        <div class="title has-text-centered">
          Training Centers <br />
          IP Camera Monitoring System
        </div>
        <div class="is-flex is-align-items-stretch is-justify-content-center ">
          <button class="button is-info is-radiusless" style="outline: 8px solid #3e8ed01f; padding: 10px 30px" (click)="login()">Proceed</button>
        </div>
      </div>
    </div>
    <div class="column"></div>
  </div>
</div>
