import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropdownItem} from "../../common/interfaces";

@Component({
  selector: 'app-app-dropdown',
  templateUrl: './app-dropdown.component.html',
  styleUrls: ['./app-dropdown.component.scss']
})
export class AppDropdownComponent implements OnInit {
  @Input() items: DropdownItem[] = [];
  @Input() label: string = 'Select';
  @Output() onSelect: EventEmitter<DropdownItem> = new EventEmitter()
  @Input() value?: string = '';
  @Input() loading: boolean = false;
  @Input() withIcon: boolean = false;
  selectedValue?: DropdownItem;
  toggle: boolean = false;
  @Input() style: string = "style-1"

  constructor() {

  }

  ngOnInit(): void {
    //if(this.value == 0 && this.value == undefined) this.value = '';
  }

  SelectItem() {
    this.selectedValue = this.items.find(m => m.value == this.value);
    this.toggle = !this.toggle;
    this.onSelect.emit(this.selectedValue);
  }

  select2Data(){
    return this.items.map(item => ({
      label: item.label,
      value: item.value,
      data: { color: 'red' }
    }));
  }

  modelChange($event: any) {
    //if(this.value == 0) this.value = '';
  }
}
