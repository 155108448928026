import {Component, OnInit, SecurityContext} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {Camera, OrganisationClient, TrainingCenter, VideoFileSegment,} from "../../schematics/apiCore";
import dayjs from 'dayjs';
import {AuthService} from "@auth0/auth0-angular";
import {exportCSVFile, icons} from "../../common/helpers";
import {Auth0User, CamReportItem, CenterBatch2, RsldcClient} from "../../schematics/openApiClients";
import {environment} from "../../../environments/environment";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  Cameras: Camera[] = [];
  RsldcBatches: CenterBatch2[] = [];
  selectedCenter!: TrainingCenter;
  icons = icons;

  permissions: { recording: boolean, reports: boolean, commandCenter: boolean, attendance: boolean, ptz: boolean }
  recordings: VideoFileSegment[] = [];
  loaders: any = {};
  profile?: Auth0User;
  user: any;
  ptz = {
    showPtzWindow: false,
    ptzUrl: environment.ezvizLiveUrl
  }


  constructor(
    public domSanitizer: DomSanitizer,
    private authService: AuthService,
    private org: OrganisationClient,
    private rsldc: RsldcClient) {
    this.permissions = {
      recording : false,
      reports: false,
      commandCenter: false,
      attendance: false,
      ptz: false
    }
  }


  async ngOnInit() {
    if (!environment.publicMode) {
      this.authService.user$.subscribe(user => {
        this.user = user;
      });
    }

    this.profile = await lastValueFrom(this.org.getUserProfile());
    this.permissions = {
      recording: this.profile.permissions?.includes('Recording') ?? false,
      reports: this.profile.permissions?.includes('CamReportDownload') ?? false,
      commandCenter: this.profile.permissions?.includes('CommandCenter') ?? false,
      attendance: this.profile.permissions?.includes('AttendanceReports') ?? false,
      ptz: this.profile.permissions?.includes('PTZ') ?? false,
    }
  }

  get isPublicMode(){
    return environment.publicMode;
  }

  async onCenterSelection(center: TrainingCenter | undefined){
    this.selectedCenter = center ?? {} as TrainingCenter;
    if(this.selectedCenter){
      this.Cameras = await lastValueFrom(this.org.getCameras(this.selectedCenter.id ?? 0));
      document.title = `RSLDC Monitoring - Center ${this.selectedCenter.centerName}`;
      if(!this.isPublicMode)
        await this.loadBatchesInfoFromRSLDC();
    }
  }

  async exportCamReport() {
    this.loaders.reportDownload = true;
    let data = await lastValueFrom(this.org.getCamreport()).catch(_ => [] as CamReportItem[]);
    this.loaders.reportDownload = false
    if(data.length > 0){
      exportCSVFile({
        scheme: 'Scheme',
        pia: 'Name of PIA',
        centerName: 'Name of SDC',
        totalCams: 'No. of Camera Installed',
        activeCams: 'No. of Camera Active',
        inactiveCams: 'No. of Camera Inactive',
        offtimeCams: 'No. of Camera under Off Time',
        archivedCams: 'No. of Archieved Camera',
        remarks: 'Specific Remark given by PIA'
      }, data, `cognota-cam-report-${dayjs().format('YYYYMMDD-HHmmss')}`);
    }
  }

  async loadBatchesInfoFromRSLDC(){
    this.loaders.batches = true;
    this.RsldcBatches = await lastValueFrom(this.rsldc.getBatches2(this.selectedCenter.id ?? 0));
    this.loaders.batches = false;
  }

  dateFormat(date: string, format: string){
    return date.split(' ')[0];
  }

  openPtzWindow(ezvizQuery: string) {
    this.ptz.ptzUrl =  `${environment.ezvizLiveUrl}?q=${ezvizQuery}`;
    this.ptz.showPtzWindow = true;
  }

  protected readonly SecurityContext = SecurityContext;
}
