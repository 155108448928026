<button class="button is-small is-bold {{classList}}" (click)="paginate(-1)">
       <span class="icon">
        <fa-icon [icon]="icons.left" />
       </span>
</button>
<button class="button is-small is-bold {{classList}} ml-1 mr-1">
  Page {{page}} of {{ totalPages }}
</button>
<button class="button is-small is-bold {{classList}}" (click)="paginate(1)">
       <span class="icon">
        <fa-icon [icon]="icons.right" />
       </span>
</button>
