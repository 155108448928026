@if(currentCenter && currentCenter.active && currentCenter.biometricEnabled){
  <div class="columns is-marginless has-background-info p-2">
    <div class="column is-8 has-background-info p-0">
      <a href="/home" class="button is-small is-info settings-button mr-2">
         <span class="icon">
          <fa-icon [icon]="icons.home" />
         </span>
        <span>Home</span>
      </a>
      <app-app-dropdown *ngIf="Batches"
                        [items]="dropdown_batches"
                        [label]="'Batches'"
                        [value]="isOk(selections.batch, '')"
                        [loading]="loaders.batch"
                        (onSelect)="onBatchSelect($event)"
      ></app-app-dropdown>
      <app-app-dropdown *ngIf="Dates"
                        [items]="dropdown_dates"
                        [label]="'Day'"
                        [value]="isOk(selections.day, '')"
                        [loading]="loaders.day"
                        (onSelect)="onDaySelect($event)"
      ></app-app-dropdown>
      <app-app-dropdown *ngIf="Cameras"
                        [items]="dropdown_cameras"
                        [label]="'Cameras'"
                        [value]="isOk(selections.camera, '')"
                        [loading]="loaders.camera"
                        (onSelect)="onCameraSelect($event)"
      ></app-app-dropdown>
      <button class="button is-small is-info settings-button mr-2"
              *ngIf="selections.camera && selections.camera !== ''"
              (click)="openBiometricModal()">
         <span class="icon">
          <fa-icon [icon]="icons.camera" />
         </span>
        <span>Capture Attendance</span>
      </button>
    </div>
    <div class="column is-4 has-background-info p-0 has-text-right">
      <div class="mt-2 mr-2">
        <span class="heading has-text-weight-bold has-text-white">Live Attendance Manager</span>
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column p-3 is-6 section">
      <div class="box-title p-2 has-text-black heading">Batch Students - {{Students.length}}</div>
      <div class="live-Video p-3" *ngIf="selections.camera && selections.camera !== ''">
        @if(video_camera){
          <app-video
            [RecordingPlaybackMode]="false"
            [CommandCenterMode]="false"
            [ShowRecordingButton]="false"
            [Camera]="video_camera"
            [width]="592"
            [height]="333"
            #videoBox
          ></app-video>
          <div class="buttons">
            <button class="button is-small is-info" [class.is-loading]="loaders.captureFrame" (click)="captureFrame()">Fetch Attendance</button>
            <button class="button is-small" *ngIf="matchResults">Found {{matchResults.matches?.length ?? 0}} students present</button>
          </div>
        }
      </div>
      <div class="students-wrapper p-3">
        @for(student of studentGrid; track student.aadhar){
          <div class="student-block mr-3 mb-3" [class.is-active]="selections.student === student.aadhar">
            <div class="student-img-block">
              <img *ngIf="!!student.imageUrl"
                   class="student-img"
                   [ngSrc]="student.imageUrl.startsWith('http') ? student.imageUrl : environment.apiService.url + student.imageUrl"
                   alt="" [width]="100" [height]="130" priority/>
            </div>
            <div class="student-details">
              <div>{{student.name}}</div>
              <div>{{student.aadhar}}</div>
            </div>
          </div>
        }
      </div>
    </div>
    <div class="column p-3 is-2 section">
      <div class="box-title p-2 has-text-black heading">Attendance - {{selections.day}}</div>
      <progress *ngIf="loaders.attendance" class="progress is-small is-danger" max="100" style="border-radius: 0; height: 2px; margin-bottom: 0"></progress>
      <div class="table-container">
        <!-- Attendance Table -->
        <table class="table is-bordered heading is-fullwidth table-grid">
          <thead>
          <tr>
            <th>Time</th>
            <th>Available Presence</th>
          </tr>
          </thead>
          <tbody>
            @for(item of paginatedItems(attendance, paginations.table1.page, paginations.table1.page_size); track item.id){
              <tr style="cursor: pointer" (click)="onAttendanceSelect(item)" [class.selected]="selections.attendance === item.id">
                <td>{{item.day.format('HH:mm a')}}</td>
                <td>{{item.present}}</td>
              </tr>
            }
          </tbody>
          <tfoot>
          <tr>
            <td colspan="2">
              <app-pagination
                [page]="paginations.table1.page"
                [totalPages]="paginations.table1.total_pages()"
                [classList]="'is-danger'"
                (onPageChange)="onTable1PageChange($event)" ></app-pagination>
            </td>
          </tr>
          </tfoot>
        </table>

        <!-- Attendance Stats Table -->
        <table class="table is-bordered heading table-grid is-fullwidth">
          <thead>
            <tr>
              <th>Min</th>
              <th>Max</th>
              <th>Avg</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{attendanceStats.min}}</td>
            <td>{{attendanceStats.max}}</td>
            <td>{{attendanceStats.avg}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column p-3 is-3 section">
      <div class="box-title p-2 has-text-black heading">Attendance Details - {{attendanceDetails.length}}</div>
      <progress *ngIf="loaders.attendance" class="progress is-small is-danger" max="100" style="border-radius: 0; height: 2px; margin-bottom: 0"></progress>
      <div>
        <!-- Attendance Details Table -->
        <table class="table is-bordered heading is-fullwidth table-grid">
          <thead>
         <tr>
           <th>Name</th>
           <th>Present?</th>
         </tr>
          </thead>
          <tbody>
            @for(item of paginatedItems(attendanceDetails, paginations.table2.page, paginations.table2.page_size); track item.id){
              <tr style="cursor: pointer" (click)="onAttendanceDetailSelect(item)" [class.selected]="selections.attendanceDetail === item.id">
                <td>{{item.studentName}}</td>
                <td [class.red]="!item.present">{{item.present ? 'YES' : 'NO'}}</td>
              </tr>
            }
          </tbody>
          <tfoot>
          <tr>
            <td colspan="2">
              <app-pagination
                [page]="paginations.table2.page"
                [totalPages]="paginations.table2.total_pages()"
                [classList]="'is-danger'"
                (onPageChange)="onTable2PageChange($event)" ></app-pagination>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="column is-1"></div>
  </div>
}
@else{
  <div class="columns is-marginless has-background-info p-2">
    <div class="column is-full p-0">
      <button class="button is-small is-info settings-button mr-2">Center is not enabled for Biometrics</button>
    </div>
  </div>
}

