<div class="columns is-marginless has-background-info p-2">
  <div class="column is-8 has-background-info p-0">
    <a href="/home" class="button is-small is-info settings-button mr-2">
       <span class="icon">
        <fa-icon [icon]="icons.home" />
       </span>
      <span>Home</span>
    </a>
    <app-dropdown-bar
      (onSchemeSelection)="onSchemeSelection($event)"
      (onCompanySelection)="onCompanySelection($event)"
      (onDistrictSelection)="onDistrictSelection($event)"
      (onCenterSelection)="onCenterSelection($event)"
      [hide]="['fy']"
    ></app-dropdown-bar>
    <button class="button is-small is-info settings-button" (click)="settingsWindow = !settingsWindow">
       <span class="icon">
        <fa-icon [icon]="icons.settings" />
       </span>
      <span>Settings</span>
    </button>

  </div>
  <div class="column is-4 has-background-info p-0 has-text-right">
    <button class="button is-small is-info settings-button ml-2" style="width: 100px" (click)="auto_paginate()">
       <span class="icon">
        <fa-icon [icon]="selections.auto_play ? icons.pause : icons.play" />
       </span>
      <span>{{selections.auto_play ? 'Stop' : 'Auto Play'}}</span>
    </button>
    <button *ngIf="query.page > 1" class="button is-small is-info is-bold is-size-7" (click)="paginate(-1)">
       <span class="icon">
        <fa-icon [icon]="icons.left" />
       </span>
    </button>
    <button class="button is-small is-info is-bold is-size-7">
      Page {{query.page}} of {{ totalPages }}
    </button>
    <button *ngIf="query.page < totalPages" class="button is-small is-info is-bold is-size-7" (click)="paginate(1)">
       <span class="icon">
        <fa-icon [icon]="icons.right" />
       </span>
    </button>
  </div>
</div>
<div class="camera-container pt-3 pl-2">
  @for(cam of Cameras; track cam.id){
    <app-video
      [Camera]="cam"
      [ShowRecordingButton]="false"
      [height]="selected_video_box_size.h"
      [width]="selected_video_box_size.w"
      [CommandCenterMode]="true"
      class="cam-thumbnail">
    </app-video>
  }

  @if(Cameras.length === 0){
    <div class="box">
      No Cameras Found for this filter
    </div>
  }




</div>
<div class="modal" [class.is-active]="settingsWindow">
  <div class="modal-background"></div>
  <div class="modal-card" style="width: 1000px">
    <header class="modal-card-head">
      <span class="modal-card-title">Command Center Settings</span>
    </header>
    <section class="modal-card-body p-5">
      <div class="columns">
        <div class="column is-2 field">
          <div>Video Box Size</div>
          <div class="mt-1"><app-app-dropdown [items]="settings.dropdown_box_size" label="Video Box Size" [value]="selections.box_size"
                                 (onSelect)="onBoxSizeChange($event)"></app-app-dropdown></div>
        </div>
        <div class="column is-2 field">
          <div>Cameras/page</div>
          <div class="mt-1"><app-app-dropdown [items]="settings.page_sizes" label="Video Box Size" [value]="selections.page_size.toString()"
                                              (onSelect)="onPageSizeChange($event)"></app-app-dropdown></div>
        </div>
        <div class="column is-2 field">
          <div>Start Page</div>
          <div class="mt-1"><app-app-dropdown [items]="settings.start_page()" label="Video Box Size" [value]="selections.page.toString()"
                                              (onSelect)="onStartPageChange($event)"></app-app-dropdown></div>
        </div>
        <div class="column is-2 field">
          <div>Wait Delay</div>
          <div class="mt-1"><app-app-dropdown [items]="settings.slide_show_delay" label="Wait Delay" [value]="selections.delay.toString()"
                                              (onSelect)="onSlideShowDelayChange($event)"></app-app-dropdown></div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons">
        <button class="button is-small is-info" (click)="saveSettings()">Save</button>
        <button class="button is-small" (click)="settingsWindow = !settingsWindow">Cancel</button>
      </div>
    </footer>
  </div>
</div>
