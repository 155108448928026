import {CanActivateFn} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "@auth0/auth0-angular";
import {lastValueFrom} from "rxjs";
import {OrganisationClient} from "./apiCore";
import {FeaturePermissionName} from "./openApiClients";

export const featurePermissionsGuard: CanActivateFn = async (route, state) => {

  const orgClient = inject(OrganisationClient);

  if(route.url[0].path === 'recordings')
    return await lastValueFrom(orgClient.checkFeatureAccess(FeaturePermissionName.Recording));

  if(route.url[0].path === 'command-center')
    return await lastValueFrom(orgClient.checkFeatureAccess(FeaturePermissionName.CommandCenter));

  if(route.url[0].path === 'live-attendance')
    return await lastValueFrom(orgClient.checkFeatureAccess(FeaturePermissionName.AttendanceReports));


  return false;
};
