import {
  faAngleLeft,
  faAngleRight,
  faCogs,
  faFileVideo,
  faHome,
  faImage,
  faMicrophone,
  faPause,
  faPlay,
  faVideo,
  faVideoCamera
} from "@fortawesome/free-solid-svg-icons";

export const strip_text = (content?: string, length: number = 20) => content && content?.length <= length ? content : `${content?.substring(0, length)}...`;
export const convertToCSV = (objArray: any[]): string => {
  let array = objArray;
  let str = '';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line != '') line += ','
      line += array[i][index];
    }

    str += line + '\r\n';
  }
  return str;
}
export const exportCSVFile = (headers: any, items: any[], fileTitle: string) => {
  if (headers) {
    items.unshift(headers);
  }
  let csv = convertToCSV(items);
  let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  let link = document.createElement("a");
  if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", exportedFilenmae);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
export const icons = {
  settings: faCogs,
  left: faAngleLeft,
  right: faAngleRight,
  home: faHome,
  play: faPlay,
  pause: faPause,
  faVideo: faVideo,
  fileVideo: faFileVideo,
  camera: faVideoCamera,
  image: faImage,
  mic: faMicrophone
}

export const isOk = (value: any, defaultValue: any) => {
  if(value === undefined || value === null || value === '' || value === 0)
    return defaultValue;

  return value;
}

export const paginatedItems = (items: any[], page_number: number, page_size: number) => {
  return items.slice((page_number - 1) * page_size, page_number * page_size);
}

export const canvas2image = (videoElement: HTMLVideoElement) => {
  const canvas = document.createElement('canvas');

  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;
  canvas.getContext("2d")?.drawImage(videoElement, 0, 0, videoElement.videoWidth, videoElement.videoHeight);

  return canvas.toDataURL("image/png");
}
