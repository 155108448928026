
export const environment = {
  production: false,
  unloadCameras: true,
  publicMode: false,
  authBypass: 'NOT APPLICABLE',
  apiKey : 'a239c457-8a6e-48e7-81e9-a6ae6bb86026',
  ezvizLiveUrl: 'https://thankful-coast-028a54310.6.azurestaticapps.net',
  auth0: {
    domain: 'https://cognotaindia.au.auth0.com',
    clientId: 'eSIDHtk7hNtyJnZ94p8pqxNJq7BrEzCJ'
  },
  apiService: {
    url: 'https://rsldc-core-dev.service.cognotaindia.com',
    servers: [
      "https://rsldc-core-dev.service.cognotaindia.com",
      "https://rsldc-core-dev.service.cognotaindia.com"
    ]
  }
};


