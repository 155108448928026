import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.authService.user$.subscribe(user => {
      console.log(user);
    })

    this.authService.isAuthenticated$.subscribe(state => {
      console.log(state);
      if(state)
        this.router.navigateByUrl('/home')
    })
  }

  login(){
    this.authService.loginWithRedirect({authorizationParams: { redirect_uri : document.location.origin + '/home'}});
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async ngAfterViewInit() {

  }
}
