<div class="video-box">
  <div class="terminal" [style]="boxSize">
    <div class="status">{{Camera.archived ? 'CAM ARCHIVED' : ''}}</div>
    <div class="main-title">
      {{Camera.id}}{{Camera.name === Camera.id ? '' : ' - ' + camName}}
    </div>
    <div class="offline-wrapper" *ngIf="!Camera.online">
      <img src="assets/offline.png" class="cam-image" alt="camera offline"/>
      <div class="offline-message">{{Camera.archived? 'ARCHIVED' : 'OFFLINE'}}</div>
    </div>
    <video [style]="videoSize" #video></video>
  </div>

  @if(Camera && Camera.id && !CommandCenterMode){
    <div class="mt-2">
      @if(ShowRecordingButton){
        <button class="button is-outlined is-small" (click)="openRecordings()">
          <fa-icon [icon]="icons.fileVideo" class="is-danger"></fa-icon> &nbsp; Recordings
        </button>
      }
    </div>
  }

  @if (CommandCenterMode){
    <app-cam-metadata [camera]="Camera" [style]="{width: boxSize.width}" />
  }
</div>
