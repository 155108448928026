@if(currentCenter && currentCenter.biometricEnabled){
  <div class="columns is-marginless has-background-info p-2">
    <div class="column is-8 has-background-info p-0">
      <a href="/home" class="button is-small is-info settings-button mr-2">
         <span class="icon">
          <fa-icon [icon]="icons.home" />
         </span>
        <span>Home</span>
      </a>
      <app-app-dropdown *ngIf="Batches"
                        [items]="dropdown_batches"
                        [label]="'Batches'"
                        [value]="isOk(selections.batch, '')"
                        [loading]="loaders.batch"
                        (onSelect)="onBatchSelect($event)"
      ></app-app-dropdown>

      <app-app-dropdown *ngIf="Cameras"
                        [items]="dropdown_cameras"
                        [label]="'Cameras'"
                        [value]="isOk(selections.camera, '')"
                        [loading]="loaders.camera"
                        (onSelect)="onCameraSelect($event)"
      ></app-app-dropdown>

    </div>
    <div class="column is-4 has-background-info p-0 has-text-right">
      <div class="mt-2 mr-2">
        <span class="heading has-text-weight-bold has-text-white">Live Attendance Manager</span>
      </div>
    </div>
  </div>
  <div class="columns">
    <div class="column p-3 is-6 section">
      <div class="box-title p-2 has-text-black heading">
        Batch Students - {{Students.length}}<br />
        Double Click to see Day summary for a student
      </div>
      <div class="live-Video p-3" *ngIf="selections.camera && selections.camera !== ''">
        @if(video_camera){
          <app-video
            [RecordingPlaybackMode]="false"
            [CommandCenterMode]="false"
            [ShowRecordingButton]="false"
            [Camera]="video_camera"
            [width]="592"
            [height]="333"
            #videoBox
          ></app-video>
          <div class="buttons">
            <button class="button is-small is-info" [class.is-loading]="loaders.captureFrame" (click)="captureFrame()">Fetch Attendance</button>
            <button class="button is-small" *ngIf="matchResults">Found {{matchResults.matches?.length ?? 0}} students present</button>
          </div>
        }
      </div>
      <div class="students-wrapper p-3">
        @for(student of studentGrid; track student.aadhar){
          <div class="student-block mr-3 mb-3" [class.is-active]="selections.student === student.aadhar" (dblclick)="onStudentSelect(student)">
            <div class="student-img-block">
              <img *ngIf="!!student.imageUrl"
                   class="student-img"
                   [ngSrc]="student.imageUrl.startsWith('http') ? student.imageUrl : environment.apiService.url + student.imageUrl"
                   alt="" [width]="100" [height]="130" priority/>
            </div>
            <div class="student-details">
              <div>{{student.name}}</div>
              <div>{{student.aadhar}}</div>
            </div>
          </div>
        }
      </div>

      <div class="modal" [class.is-active]="showStudentSummary">
        <div class="modal-background" (click)="showStudentSummary = false"></div>
        <div class="modal-content">
          <div class="box">
            <div>
              <span class="is-size-7 heading">{{selectedStudent.name}} was present at following times on {{dayjs(selections.day).format('DD-MM-YYYY')}}</span>
            </div>
            <hr/>
            <div>
              @for(time of StudentSummary; track time.id){
                <div class="time-summary" (click)="onAttendanceSelect(time); openAttendanceImage($event, time)">{{time.day?.format('hh:mm a')}}</div>
              }
            </div>
          </div>
        </div>
        <button class="modal-close is-large" aria-label="close" (click)="showStudentSummary = false"></button>
      </div>

    </div>
    <div class="column p-3 is-2 section">
      <div class="box-title p-2 has-text-black heading">On {{dayjs(selections.day).format('DD-MM-YYYY')}}</div>
      <progress *ngIf="loaders.attendance" class="progress is-small is-danger" max="100" style="border-radius: 0; height: 2px; margin-bottom: 0"></progress>
      <input type="date" id="date-picker" class="is-hidden" />
      <div class="box-title p-2 mt-3 has-text-black heading">Attendance Per Day Captures</div>
      <div class="table-container">
        <!-- Attendance Table -->
        <table class="table is-bordered heading is-fullwidth table-grid">
          <thead>
          <tr>
            <th>Time</th>
            <th>Verified Presence</th>
            <th>Image</th>
          </tr>
          </thead>
          <tbody>
            @for(item of paginatedItems(attendance, pagination.table1.page, pagination.table1.page_size); track item.id){
              <tr style="cursor: pointer" (click)="onAttendanceSelect(item)" [class.selected]="selections.attendance === item.id">
                <td>{{item.day.format('hh:mm a')}}</td>
                <td>{{item.present}}</td>
                <td>
                    <button class="button is-small" (click)="openAttendanceImage($event, item)">
                        <fa-icon [icon]="icons.image" />
                    </button>
                </td>
              </tr>
            }
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3">
                <app-pagination
                  [page]="pagination.table1.page"
                  [totalPages]="pagination.table1.total_pages()"
                  [classList]="'is-danger'"
                  (onPageChange)="onTable1PageChange($event)" ></app-pagination>
              </td>

            </tr>
          </tfoot>
        </table>

        <!-- Attendance Stats Table -->
        <table class="table is-bordered heading table-grid is-fullwidth is-hidden">
          <thead>
            <tr>
              <th>Min</th>
              <th>Max</th>
              <th>Avg</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{attendanceStats.min}}</td>
            <td>{{attendanceStats.max}}</td>
            <td>{{attendanceStats.avg}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="column p-3 is-3 section">
      @if(modeSelection === "day"){
        <div class="box-title p-2 has-text-black heading">Total Summary on {{dayjs(selections.day).format('DD-MM-YYYY')}}</div>
      }

      @if(modeSelection === "time"){
        <div class="box-title p-2 has-text-black heading">Total Summary at {{selections.time}}</div>
      }

      <progress *ngIf="loaders.attendance" class="progress is-small is-danger" max="100" style="border-radius: 0; height: 2px; margin-bottom: 0"></progress>
      <div>
        <!-- Attendance Details Table -->
        <table class="table is-bordered heading is-fullwidth table-grid">
          <thead>
         <tr>
           <th>Name</th>
           <th>Present?</th>
         </tr>
          </thead>
          <tbody>
            @for(item of paginatedItems(attendanceDetails, pagination.table2.page, pagination.table2.page_size); track item.studentAadhar){
              <tr style="cursor: pointer" (click)="onAttendanceDetailSelect(item)" [class.selected]="selections.student === item.studentAadhar">
                <td>{{item.studentName}}</td>
                <td [class.red]="!item.present">{{item.present ? 'YES' : 'NO'}}</td>
              </tr>
            }
          </tbody>
          <tfoot>
          <tr>
            <td colspan="2">
              <app-pagination
                [page]="pagination.table2.page"
                [totalPages]="pagination.table2.total_pages()"
                [classList]="'is-danger'"
                (onPageChange)="onTable2PageChange($event)" ></app-pagination>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="column is-1"></div>
  </div>

    <!-- MODALS -->
    <div class="modal camera-video" [class.is-active]="att_modal_active">
        <div class="modal-background"></div>
        <div class="modal-card" style="width:1000px;">
            <header class="modal-card-head p-0">
                <p class="modal-card-title is-size-6 panel-heading">Attendance at {{attendanceAndDetails.att?.day?.format('DD-MM-YYYY hh:mm A')}}</p>
            </header>
            <section class="modal-card-body p-0" style="position: relative" >
                <img
                    [ngSrc]="environment.apiService.url + '/open-apis/attendance/' + selections.attendance + '/image'"
                    [width]="1920"
                    [height]="1080"
                    alt="" />
                @for(box of attendanceAndDetails.det; track box.studentAadhar){
                    <div class="bounding-box"
                         *ngIf="box.showBox"
                         [style.width]="box.box.width + 'px'"
                         [style.height]="box.box.height + 'px'"
                         [style.top]="box.box.top + 'px'"
                         [style.left]="box.box.left + 'px'"
                    >
                        <div class="name">{{box.studentName}}</div>
                    </div>
                }
            </section>
            <footer class="modal-card-foot">
                <div class="buttons">
                    <button class="button is-small is-danger" (click)="closeAttendanceImageModal()">Close</button>
                </div>
            </footer>
        </div>
    </div>
}
@else{
  <div class="columns is-marginless has-background-info p-2">
    <div class="column is-full p-0">
      <button class="button is-small is-info settings-button mr-2">Center is not enabled for Biometrics</button>
    </div>
  </div>
}

