import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./Pages/home/home.component";
import {LoginComponent} from "./Pages/login/login.component";
import {AuthGuard} from '@auth0/auth0-angular';
import {RecordingsComponent} from "./Pages/recordings/recordings.component";
import {featurePermissionsGuard} from "./schematics/feature-permissions.guard";
import {environment} from "../environments/environment";
import {CommandCenterComponent} from "./Pages/command-center/command-center.component";
import {LiveAttendanceComponent} from "./Pages/live-attendance/live-attendance.component";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'recordings', component: RecordingsComponent, canActivate: [AuthGuard, featurePermissionsGuard] },
  { path: 'command-center', component: CommandCenterComponent, canActivate: [AuthGuard, featurePermissionsGuard] },
  { path: 'live-attendance', component: LiveAttendanceComponent, canActivate: [AuthGuard, featurePermissionsGuard] },
];

if(environment.publicMode){
  routes[0].redirectTo = 'home';
  routes[2].canActivate = [];
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
