import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  Camera,
  IFaceMatchResults,
  ILiveAttendance2,
  ILiveAttendanceDetail,
  IStudent,
  ITrainingCenter2,
  OrganisationClient,
  PresenceClient
} from "../../schematics/openApiClients";
import {ActivatedRoute} from "@angular/router";
import {lastValueFrom} from "rxjs";
import {canvas2image, icons, isOk, paginatedItems} from "../../common/helpers";
import {DropdownItem} from "../../common/interfaces";
import {generateQueryString} from "../../common/constants";
import {environment} from "../../../environments/environment";
import {VideoComponent} from "../../components/video/video.component";
import {RsldcApiService} from "../../schematics/rsldc-api.service";
import _ from "lodash";
import bulmaCalendar from "bulma-calendar";
import dayjs from "dayjs";


@Component({
  selector: 'app-live-attendance',
  templateUrl: './live-attendance.component.html',
  styleUrl: './live-attendance.component.scss'
})
export class LiveAttendanceComponent implements OnInit, AfterViewInit {
  @ViewChild('videoBox') videoBox!: VideoComponent;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;

  protected readonly isOk = isOk;
  protected readonly icons = icons;
  protected readonly paginatedItems = paginatedItems;
  protected readonly environment = environment;

  Batches: any[] = [];
  Dates: string[] = [];
  Students: IStudent[] = [];
  Cameras: Camera[] = [];
  StudentSummary: ILiveAttendance2[] = [];

  Mock: boolean = false;

  modeSelection: "day" | "time" = "day";

  attendance: ILiveAttendance2[] = [];
  attendanceDetails: ILiveAttendanceDetail[] = [];
  matchResults?: IFaceMatchResults;
  currentCenter!: ITrainingCenter2;
  calendar!: bulmaCalendar;

  selections = {
    center: 0,
    batch: '',
    day: '',
    time: '',
    camera: '',
    attendance: 0,
    student: '',
    mock: false
  }

  loaders = {
    center: false,
    batch: false,
    day: false,
    attendance: false, camera: false,
    captureFrame: false
  }

  // table-1 - Attendance Captures
  // table-2 - Students
  pagination = {
    table1:{
      page: 1,
      page_size: 10,
      total_records: 10,
      total_pages: () => Math.ceil(this.pagination.table1.total_records / this.pagination.table1.page_size)
    },
    table2:{
      page: 1,
      page_size: 200,
      total_records: 10,
      total_pages: () => Math.ceil(this.pagination.table2.total_records / this.pagination.table2.page_size)
    },
  }

  QP: any = {};
  att_modal_active: boolean = false;
  showStudentSummary: boolean = false;
  selectedStudent!: IStudent;

  constructor(
    private presenceClient: PresenceClient,
    private apiService: RsldcApiService,
    private orgClient: OrganisationClient,
    private route: ActivatedRoute) {


    this.QP = {
      ...route.snapshot.queryParams,
      center: parseInt(this.route.snapshot.queryParams['center'])
    }

    this.selections = {
      ...this.QP,
      camera: '',
      day: this.QP.day ?? dayjs().format('YYYY-MM-DD'),
    };
  }

  async ngAfterViewInit() {

  }

  createCalender(){

    this.calendar = bulmaCalendar.attach('[id="date-picker"]', {
      type: "date",
      displayMode: "inline",
      color: "secondary",
      dateFormat: "yyyy-MM-dd",
      highlightedDates: [...this.Dates],
      showHeader: false,
      showFooter: false,
      showButtons: false,
      isRange: false,
    })[0];

    this.calendar.on('select', async e => {
      await this.onDaySelect({value: dayjs(e.data.date.start).format('YYYY-MM-DD'), label: ''});
    });
  }


  async ngOnInit() {

    this.loaders.batch = true;
    this.currentCenter = await lastValueFrom(this.orgClient.getTrainingCenterById(this.selections.center));

    await this.loadBatches();
    await this.loadCameras();

    this.loaders.batch = false;

    if(this.selections.batch)
      await this.onBatchSelect({value: this.selections.batch, label: ''});

    if(this.selections.day)
      await this.onDaySelect({value: this.selections.day, label: ''});


    this.createCalender();
  }

  async loadBatches(){
    this.Batches = await lastValueFrom(this.presenceClient.getBatches(this.selections.center));
  }

  async loadDays(){
    if(this.selections.batch)
      this.Dates = await lastValueFrom(this.presenceClient.getAttendanceDays(this.selections.center, this.selections.batch))

    this.createCalender();


    if(this.selections.day)
      await this.onDaySelect({value: this.selections.day, label: ''});
  }

  async loadCameras(){
    this.Cameras = await lastValueFrom(this.presenceClient.getCameras(this.selections.center))
  }

  get studentGrid(){
    if(this.Students && this.Students.length > 0)
      return this.Students;

    return [1,2,3,4,5].map(() => ({name: '', aadhar: '', regNo: '', imageUrl: 'https://assets.cognotaindia.com/user-image.png'}) as IStudent)
  }

  get dropdown_batches(){
    return this.Batches.map(e => ({ label: e.value, value: e.id  }));
  }

  get dropdown_dates(){
    return this.Dates.map(e => ({ label: e, value: e  }));
  }

  get dropdown_cameras(){
    return this.Cameras.map(e => ({ label: e.name ?? '', value: e.id ?? ''  }));
  }

  get video_camera(): Camera{
    return this.Cameras.find(m => m.id === this.selections.camera) as Camera;
  }

  async onBatchSelect(batch: DropdownItem) {
    this.loaders.day = true;
    this.selections.batch = batch.value.toString();
    await this.loadDays();
    generateQueryString(this.selections, this.route.snapshot.queryParams);
    this.loaders.day = false;

    this.loaders.batch = true;
    this.Students = await lastValueFrom(this.presenceClient.getStudents(this.selections.center, this.selections.batch));
    this.loaders.batch = false;

  }

  async onDaySelect(day: DropdownItem) {
    this.loaders.attendance = true;
    this.modeSelection = "day";
    this.selections.day = day.value.toString();
    generateQueryString(this.selections, this.route.snapshot.queryParams);

    this.attendance =
      await lastValueFrom(this.presenceClient.getAttendance(this.selections.center, this.selections.batch, this.selections.day))

    this.attendanceDetails = await lastValueFrom(this.presenceClient.getAttendanceSummary(this.selections.center, this.selections.batch, this.selections.day));


    this.pagination.table1.page = 1;
    this.pagination.table1.total_records = this.attendance.length;

    this.pagination.table2.page = 1;
    this.pagination.table2.total_records = this.attendanceDetails.length;

    this.loaders.attendance = false;
  }



  onTable1PageChange(page: number) {
    this.pagination.table1.page = page;
  }

  async onCameraSelect(camera: DropdownItem) {
    if(camera)
      this.selections.camera = camera.value.toString();
    else
      this.selections.camera = '';

    this.videoBox.Start();
    generateQueryString(this.selections, this.route.snapshot.queryParams);
  }



  async onAttendanceSelect(item: ILiveAttendance2) {
    this.loaders.attendance = true;
    this.selections.attendance = item.id ?? 0;
    this.modeSelection = "time";
    const att = this.attendance.find(m => m.id === item.id);
    if(att)
      this.selections.time = att.day?.format('hh:mm a') ?? '00:00';


    this.attendanceDetails = await lastValueFrom(this.presenceClient.getLiveAttendanceDetail(item.id))
    this.pagination.table2.page = 1;
    this.pagination.table2.total_records = this.attendanceDetails.length;
    this.loaders.attendance = false;
  }

  onTable2PageChange(page: number) {
    this.pagination.table2.page = page;
  }

  async onStudentSelect(student: IStudent) {
    this.selectedStudent = student;
    this.showStudentSummary = true;
    this.StudentSummary = await lastValueFrom(this.presenceClient.getStudentAttendanceSummary(this.selections.center, this.selections.batch, this.selections.day, student.aadhar))

  }

  async captureFrame() {
    this.loaders.captureFrame = true;
    const base64 = canvas2image(this.videoBox.VideoRefElement());

    this.matchResults = await this.apiService.FindMatchByFace(this.selections.center, this.selections.batch, base64, this.selections.mock);
    console.log(this.matchResults);

    await this.loadDays();

    this.loaders.captureFrame = false;
  }

  get attendanceStats(){
    return {
      min: _.minBy(this.attendance, 'present')?.present,
      max: _.maxBy(this.attendance, 'present')?.present,
      avg: Math.round(_.sumBy(this.attendance, 'present') / this.attendance.length),
    }
  }


  onAttendanceDetailSelect(item: ILiveAttendanceDetail) {
    this.selections.student = item.studentAadhar ?? '';
  }

  async openAttendanceImage($event: MouseEvent, item: ILiveAttendance2) {
    //$event.stopPropagation();
    this.att_modal_active = true;
  }

  get attendanceAndDetails(){
    return {
      att: this.attendance.find(m => m.id === this.selections.attendance),
      det: this.attendanceDetails.map(e => ({
        ...e,
        showBox: e.boundingBox !== '0,0,0,0',
        box: {
          height: (parseFloat((e.boundingBox ?? '0,0,0,0') ?.split(',')[0]) * 563) + 10,
          width: (parseFloat((e.boundingBox ?? '0,0,0,0') ?.split(',')[1]) * 1000) + 10,
          left: (parseFloat((e.boundingBox ?? '0,0,0,0') ?.split(',')[2]) * 1000) - 5,
          top: (parseFloat((e.boundingBox ?? '0,0,0,0') ?.split(',')[3]) * 563) -5,
        }
      }))
    }
  }

  closeAttendanceImageModal() {
    this.att_modal_active = false;
  }

  protected readonly dayjs = dayjs;
}
