import {Component, ElementRef, Input, OnInit, ViewChild, ViewRef} from '@angular/core';
import {
  Camera,
  ICamera, IFaceMatchResults,
  ILiveAttendance2,
  ILiveAttendanceDetail,
  IStudent2, ITrainingCenter2, OrganisationClient,
  PresenceClient
} from "../../schematics/openApiClients";
import {ActivatedRoute} from "@angular/router";
import {lastValueFrom} from "rxjs";
import {canvas2image, icons, isOk, paginatedItems} from "../../common/helpers";
import {DropdownItem} from "../../common/interfaces";
import {generateQueryString} from "../../common/constants";
import {environment} from "../../../environments/environment";
import {VideoComponent} from "../../components/video/video.component";
import {RsldcApiService} from "../../schematics/rsldc-api.service";
import _ from "lodash";
@Component({
  selector: 'app-live-attendance',
  templateUrl: './live-attendance.component.html',
  styleUrl: './live-attendance.component.scss'
})
export class LiveAttendanceComponent implements OnInit {
  @ViewChild('videoBox') videoBox!: VideoComponent;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;

  protected readonly isOk = isOk;
  protected readonly icons = icons;
  protected readonly paginatedItems = paginatedItems;
  protected readonly environment = environment;

  Batches: any[] = [];
  Dates: string[] = [];
  Students: IStudent2[] = [];
  Cameras: Camera[] = [];

  attendance: ILiveAttendance2[] = [];
  attendanceDetails: ILiveAttendanceDetail[] = [];
  matchResults?: IFaceMatchResults;
  currentCenter!: ITrainingCenter2;

  selections = {
    center: 0,
    batch: '',
    day: '',
    camera: '',
    attendance: 0,
    attendanceDetail: 0,
    student: ''
  }

  loaders = {
    center: false,
    batch: false,
    day: false,
    attendance: false, camera: false,
    captureFrame: false
  }

  paginations = {
    table1:{
      page: 1,
      page_size: 20,
      total_records: 10,
      total_pages: () => Math.ceil(this.paginations.table1.total_records / this.paginations.table1.page_size)
    },
    table2:{
      page: 1,
      page_size: 20,
      total_records: 10,
      total_pages: () => Math.ceil(this.paginations.table2.total_records / this.paginations.table2.page_size)
    },
  }

  QP: any = {};
  cam_model_active: boolean = false;


  constructor(
    private presenceClient: PresenceClient,
    private apiService: RsldcApiService,
    private orgClient: OrganisationClient,
    private route: ActivatedRoute) {

    this.QP = {
      ...route.snapshot.queryParams,
      center: parseInt(this.route.snapshot.queryParams['center'])
    }

    this.selections = {
      ...this.QP,
      camera: ''
    };

  }

  async ngOnInit() {
    this.loaders.batch = true;
    this.currentCenter = await lastValueFrom(this.orgClient.getTrainingCenterById(this.selections.center));

    this.Batches = await lastValueFrom(this.presenceClient.getBatches(this.selections.center));
    this.Cameras = await lastValueFrom(this.presenceClient.getCameras(this.selections.center))
    this.loaders.batch = false;

    if(this.selections.batch)
      await this.onBatchSelect({value: this.selections.batch, label: ''});

    if(this.selections.day)
      await this.onDaySelect({value: this.selections.day, label: ''});

  }

  get studentGrid(){
    if(this.Students && this.Students.length > 0)
      return this.Students;

    return [1,2,3,4,5].map(e => ({name: '', aadhar: '', regNo: '', imageUrl: 'https://assets.cognotaindia.com/user-image.png'}) as IStudent2)
  }

  get dropdown_batches(){
    return this.Batches.map(e => ({ label: e.value, value: e.id  }));
  }

  get dropdown_dates(){
    return this.Dates.map(e => ({ label: e, value: e  }));
  }

  get dropdown_cameras(){
    return this.Cameras.map(e => ({ label: e.name ?? '', value: e.id ?? ''  }));
  }

  get video_camera(): Camera{
    return this.Cameras.find(m => m.id === this.selections.camera) as Camera;
  }

  async onBatchSelect(batch: DropdownItem) {
    this.loaders.day = true;
    this.selections.batch = batch.value.toString();
    this.Dates = await lastValueFrom(this.presenceClient.getAttendanceDays(this.selections.center, this.selections.batch))
    generateQueryString(this.selections, this.route.snapshot.queryParams);
    this.loaders.day = false;

    this.loaders.batch = true;
    this.Students = await lastValueFrom(this.presenceClient.getStudents(this.selections.center, this.selections.batch));


    this.loaders.batch = false;

  }

  async onDaySelect(day: DropdownItem) {
    this.loaders.attendance = true;
    this.selections.day = day.value.toString();
    generateQueryString(this.selections, this.route.snapshot.queryParams);

    this.attendance =
      await lastValueFrom(this.presenceClient.getAttendance(this.selections.center, this.selections.batch, this.selections.day))

    this.paginations.table1.page = 1;
    this.paginations.table1.total_records = this.attendance.length;

    this.loaders.attendance = false;
  }



  onTable1PageChange(page: number) {
    this.paginations.table1.page = page;
  }

  async onCameraSelect(camera: DropdownItem) {
    if(camera)
      this.selections.camera = camera.value.toString();
    else
      this.selections.camera = '';

    this.videoBox.Start();
    generateQueryString(this.selections, this.route.snapshot.queryParams);
  }



  async onAttendanceSelect(item: ILiveAttendance2) {
    this.loaders.attendance = true;
    this.selections.attendance = item.id ?? 0;
    this.attendanceDetails = await lastValueFrom(this.presenceClient.getLiveAttendanceDetail(item.id))
    this.paginations.table2.page = 1;
    this.paginations.table2.total_records = this.attendanceDetails.length;
    this.loaders.attendance = false;
  }

  onTable2PageChange(page: number) {
    this.paginations.table2.page = page;
  }

  async openBiometricModal(){
    this.cam_model_active = !this.cam_model_active;
    this.videoBox.Start();
  }

  async closeBiometricModal(){
    this.cam_model_active = !this.cam_model_active;
    this.videoBox.Stop();
    this.loaders.captureFrame = false;
    this.matchResults = undefined;
  }

  async captureFrame() {
    this.loaders.captureFrame = true;
    const base64 = canvas2image(this.videoBox.VideoRefElement());

    this.matchResults = await this.apiService.FindMatchByFace(this.selections.center, this.selections.batch, base64, true);
    console.log(this.matchResults);
    this.loaders.captureFrame = false;
  }

  get attendanceStats(){
    return {
      min: _.minBy(this.attendance, 'present')?.present,
      max: _.maxBy(this.attendance, 'present')?.present,
      avg: Math.round(_.sumBy(this.attendance, 'present') / this.attendance.length),
    }
  }


  onAttendanceDetailSelect(item: ILiveAttendanceDetail) {
    this.selections.attendanceDetail = item.id ?? 0;
    this.selections.student = item.studentAadhar ?? '';
  }
}
