
<app-app-dropdown *ngIf="!hide.includes('scheme')" [items]="dropdowns.schemes" label="Scheme" [value]="selections.scheme" [loading]="loaders.scheme ?? false"
                  (onSelect)="onDropdownSelectionChange($event,'scheme')"></app-app-dropdown>
<app-app-dropdown *ngIf="!hide.includes('company')" [items]="dropdowns.companies" label="Company" [value]="selections.company" [loading]="loaders.company ?? false"
                  (onSelect)="onDropdownSelectionChange($event,'company')"></app-app-dropdown>

<app-app-dropdown *ngIf="!hide.includes('fy')" [items]="dropdowns.fys" label="FY" [value]="selections.fy" [loading]="loaders.fy ?? false"
                  (onSelect)="onDropdownSelectionChange($event,'fy')"></app-app-dropdown>
<app-app-dropdown *ngIf="!hide.includes('district')" [items]="dropdowns.districts" label="District" [value]="selections.district"
                  (onSelect)="onDropdownSelectionChange($event,'district')"></app-app-dropdown>

<app-app-dropdown *ngIf="!hide.includes('center')" [items]="dropdowns.centers" label="Center" [value]="selections.center" [loading]="loaders.center ?? false"
                  (onSelect)="onDropdownSelectionChange($event,'center')"></app-app-dropdown>

