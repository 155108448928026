<div style="height: 5px">
  @if(showLoadingBar){
    <progress class="progress is-small is-danger" max="100" style="border-radius: 0; height: 5px; margin-bottom: 0"></progress>
  }
</div>
<div class="columns">
  <div class="column m-0">
    <div class="video-wrapper has-text-centered">
      <button class="button is-small is-pulled-left mt-3 ml-3" (click)="goBack()">
        <span class="icon is-small has-text-weight-bold" >
            <fa-icon [icon]="icons.back" />&nbsp;
          </span>
        <span>BACK</span>
      </button>
      <video #video autoplay muted playsinline controls></video>
      @if(selectedRecordingFile && false){
        <div class="box mt-5">
          <h1 class="title">{{selectedRecordingFile.timeRange?.item1}} - {{selectedRecordingFile.timeRange?.item2}}</h1>
          <h2 class="subtitle">{{selectedRecordingFile.videoDuration}} seconds</h2>
        </div>
      }
    </div>
  </div>
</div>
<div class="columns p-5">
  <div class="column">
    <div class="box">
      @for(month of monthYears; track month){
        <button class="button is-small mr-2 mb-2" [class.is-info]="selections.month === month" (click)="selectMonth(month)">
          <span class="icon is-small">
            <fa-icon [icon]="icons.monthButton" />
          </span>
          <span>{{month}}</span>
        </button>
      }
    </div>
    <div class="box">
      @for(day of recordingFilterDays; track day.date){
        <button class="button is-small mr-2 mb-2" style="width: 50px"
                [class.is-loading]="day.date && !!loader[fn_dayjs(day.date).format('YYYY-MM-DD')]"
                [class.is-info]="selections.day === fn_dayjs(day.date).format('YYYY-MM-DD')" (click)="selectDay(fn_dayjs(day.date).format('YYYY-MM-DD'))">
          <span>{{ fn_dayjs(day.date).format('DD')}}</span>
        </button>
      }
    </div>
    <div class="box">
      @for(hour of hours; track hour.i){
        <button class="button is-small mr-2 mb-2" style="width: 50px"
                [class.is-loading]="!!loader['h' + hour.h]"
                [class.is-info]="selections.hour === (hour.h + '')"
                (click)="selectHour(hour.h + '')"
        >
          <span>{{ hour.k }} {{hour.t}}</span>
        </button>
      }
    </div>
  </div>
  <div class="column ml-5">
    <div class="box" style="max-height: 400px; overflow: auto">
      @for(recording of recordings; track recording.time){
        <button class="button is-small mr-2 mb-2" style="width: 120px"
                [class.is-loading]="recording.name && !!loader[recording.name]"
                [class.is-info]="selections.recording === recording.name" (click)="selectRecordingFile(recording.name ?? '')">
          <span class="icon is-small">
            <fa-icon [icon]="icons.video" />&nbsp;
          </span>
          <span>{{ recording.timeRange?.item1 }}</span>
        </button>
      }
    </div>
  </div>
</div>
