import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RecordingDay, VideoFileSegment, VideoGalleryClient} from "../../schematics/openApiClients";
import {lastValueFrom} from "rxjs";
import {faCalendarDays, faFilm, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import dayjs from "dayjs";

@Component({
  selector: 'app-recordings',
  standalone: true,
  imports: [
    FontAwesomeModule
  ],
  templateUrl: './recordings.component.html',
  styleUrl: './recordings.component.scss'
})
export class RecordingsComponent implements OnInit, AfterViewInit {
  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;
  recordingDays: RecordingDay[] = [];
  recordings: VideoFileSegment[] = [];
  loader: any = {}
  selectedRecordingFile!: VideoFileSegment;
  backUrl: string;
  selections: Partial<{
    centerId: number,
    camId: string,
    month: string,
    day: string,
    hour: string,
    recording: string
  }> = {}

  icons = {
    monthButton: faCalendarDays,
    video: faFilm,
    back: faArrowLeft
  }
  showLoadingBar: boolean = true;
  constructor(private route: ActivatedRoute, private router: Router, private gallery: VideoGalleryClient) {
    this.selections.camId = route.snapshot.queryParams['camId'];
    this.selections.centerId = route.snapshot.queryParams['centerId'] * 1;

    this.selections.month = route.snapshot.queryParams['month'];
    this.selections.day = route.snapshot.queryParams['day'];
    this.selections.hour = route.snapshot.queryParams['hour']
    this.selections.recording = route.snapshot.queryParams['recording'];

    this.backUrl = route.snapshot.queryParams['returnUrl'];

    this.generateQueryString();
  }

  async ngAfterViewInit() {
    if(this.selections.month)
      this.selectMonth(this.selections.month);

    if(this.selections.day)
      this.selectDay(this.selections.day);

    if(this.selections.hour)
      await this.selectHour(this.selections.hour );

    if(this.selections.recording)
      await this.selectRecordingFile(this.selections.recording);
  }


  async ngOnInit() {
    this.recordingDays = await lastValueFrom(this.gallery.getRecordingDays(
                        this.selections.centerId ?? 0, this.selections.camId ?? 'NANANAN'));
    if(this.recordingDays.length > 0 && !this.selections.month){
      this.selections.month = this.recordingDays[0].monthStr + '-' + this.recordingDays[0].year;
      this.generateQueryString();
    }

    this.showLoadingBar = false;

  }

  get monthYears(){
    return [...new Set(this.recordingDays.map(m => `${m.monthStr}-${m.year}`).reverse())];
  }



  get fn_dayjs(){
    return dayjs;
  }

  get hours(){
    return [...Array.from({ length: 24 }, (v,i) => ({
      h: i < 10 ? '0' + i : i,
      t: i < 12 ? 'AM' : 'PM',
      i: i,
      k: i === 0 ? 12 : (i > 12 ? i - 12 : i)
    })), {
      h: 'ALL',
      t : '',
      i: 0,
      k: 'ALL'
    }]
  }

  get recordingFilterDays(){
    return this.recordingDays.filter(m => m.monthStr + '-' + m.year === this.selections.month).reverse();
  }
  selectMonth(month: string) {  // MONTH SELECTION
    this.selections.month = month;
    this.generateQueryString();

  }

  selectDay(day: string): void{
    this.selections.day = day;
    this.generateQueryString();
  }

  async selectHour(hour: string) {  // DAY SELECTION
    this.loader = {};
    this.loader['h' + hour] = true;
    this.selections.hour = hour;


    this.recordings = [];
    const selectedHour = (this.selections.hour ?? '0') === 'ALL' ? 0 : parseInt(this.selections.hour);
    this.recordings = await lastValueFrom(this.gallery.getRecordingsByDate(this.selections.centerId ?? 0,
                            this.selections.camId ?? 'NANANA', this.selections.day ?? '', selectedHour));
    this.loader = {};
    this.generateQueryString();
  }

  async selectRecordingFile(recording: string) { // RECORDING FILE SELECTION
    this.selections.recording = recording ?? '';
    this.loader[recording] = true;

    const path = this.recordings.find(m => m.name === this.selections.recording);
    if(path){
      const media = await lastValueFrom(this.gallery.downloadVideo(path?.fullPath));
      this.video.nativeElement.src = URL.createObjectURL(new Blob([media.data], {type: 'video/mp4'}));
      this.selectedRecordingFile = path;
    }


    this.loader = {};
    this.generateQueryString();
  }

  generateQueryString(){
    let qParams: any = { ...this.route.snapshot.queryParams };
    Object.keys(this.selections).forEach(e => {
      const val = (this.selections as any)[e];
      if(val){
        qParams[e] = val;
      }
    })
    const _url = this.router.serializeUrl(this.router.createUrlTree(['/recordings'], { queryParams: qParams }));

    history.replaceState(null, '', _url);
  }


  async goBack() {
    const b = decodeURI(this.backUrl.replace(document.location.origin, ''));
    console.log(b);
    await this.router.navigateByUrl(b, { replaceUrl: true });
  }
}
