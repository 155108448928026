import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";
import {StorageKey} from "../common/constants";

@Injectable()
export class PublicModeHttpInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const env = environment;
    const customReq = request.clone({
      headers : request.headers
        .set('Authorization', `Bearer ${ env.authBypass }`)
    });
    return next.handle(customReq);
  }
}


@Injectable()
export class CommonHttpInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const customReq = request.clone({
      headers : request.headers
        .set('X-Api-Key', environment.apiKey)
        .set('X-Active-Inactive', localStorage.getItem('active_inactive') ?? 'Live Data')
    });
    return next.handle(customReq);
  }
}
