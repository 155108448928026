export default class WebRtcClient {
  private pc: RTCPeerConnection | null = null;
  private restartTimeout: number | null = null;
  private sessionUrl: string = '';
  private queuedCandidates: any[] = [];
  private readonly restartPause: number = 2000;
  private offerData: any;
  private readonly videoUrl: string;
  public retryCounter = 10;
  private videoPlayer!: HTMLVideoElement
  constructor(
    videoUrl: string | undefined,
    retryCounter: number = 10,
    private eventCallbacks: (message: string, type: string, viewBag: any) => void
  ) {
    this.videoUrl = videoUrl ?? 'NA';
    this.retryCounter = retryCounter
  }

  setRetryCount(count: number){
    this.retryCounter = count;
  }

  unquoteCredential = (v: string) => (
    JSON.parse(`"${v}"`)
  );

  linkToIceServers = (links: string) => (
    (links !== null) ? links.split(', ').map((link: string) => {
      const m = link.match(/^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i);
      if(m){
        const ret: any = {
          urls: [m[1]],
        };

        if (m[3] !== undefined) {
          ret.username = this.unquoteCredential(m[3]);
          ret.credential = this.unquoteCredential(m[4]);
          ret.credentialType = "password";
        }

        return ret;
      }
      return [];
    }) : []
  );

  parseOffer = (offer: string) => {
    const ret: any = {
      iceUfrag: '',
      icePwd: '',
      medias: [],
    };

    for (const line of offer.split('\r\n')) {
      if (line.startsWith('m=')) {
        ret.medias.push(line.slice('m='.length));
      } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
        ret.iceUfrag = line.slice('a=ice-ufrag:'.length);
      } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
        ret.icePwd = line.slice('a=ice-pwd:'.length);
      }
    }

    return ret;
  };

  enableStereoOpus = (section: string) => {
    let opusPayloadFormat = '';
    let lines = section.split('\r\n');

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith('a=rtpmap:') && lines[i].toLowerCase().includes('opus/')) {
        opusPayloadFormat = lines[i].slice('a=rtpmap:'.length).split(' ')[0];
        break;
      }
    }

    if (opusPayloadFormat === '') {
      return section;
    }

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith('a=fmtp:' + opusPayloadFormat + ' ')) {
        if (!lines[i].includes('stereo')) {
          lines[i] += ';stereo=1';
        }
        if (!lines[i].includes('sprop-stereo')) {
          lines[i] += ';sprop-stereo=1';
        }
      }
    }

    return lines.join('\r\n');
  };

  editOffer = (offer: any) => {
    const sections = offer.sdp.split('m=');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (section.startsWith('audio')) {
        sections[i] = this.enableStereoOpus(section);
      }
    }

    offer.sdp = sections.join('m=');
  };

  generateSdpFragment = (offerData: any, candidates: any) => {
    const candidatesByMedia: any = {};
    for (const candidate of candidates) {
      const mid = candidate.sdpMLineIndex;
      if (candidatesByMedia[mid] === undefined) {
        candidatesByMedia[mid] = [];
      }
      candidatesByMedia[mid].push(candidate);
    }

    let frag = 'a=ice-ufrag:' + offerData.iceUfrag + '\r\n'
      + 'a=ice-pwd:' + offerData.icePwd + '\r\n';

    let mid = 0;

    for (const media of offerData.medias) {
      if (candidatesByMedia[mid] !== undefined) {
        frag += 'm=' + media + '\r\n'
          + 'a=mid:' + mid + '\r\n';

        for (const candidate of candidatesByMedia[mid]) {
          frag += 'a=' + candidate.candidate + '\r\n';
        }
      }
      mid++;
    }

    return frag;
  }

  onIceServers(res: any) {
    this.pc = new RTCPeerConnection({
      iceServers: this.linkToIceServers(res.headers.get('Link')),
      sdpSemantics: 'unified-plan',
    } as any);

    const direction = "sendrecv";
    this.pc.addTransceiver("video", { direction });
    this.pc.addTransceiver("audio", { direction });

    this.pc.onicecandidate = (evt) => this.onLocalCandidate(evt);
    this.pc.oniceconnectionstatechange = () => this.onConnectionState();

    this.pc.ontrack = (evt) => {
      this.retryCounter = 10;
      this.eventCallbacks("new track:", evt.track.kind, {});
      if(this.videoPlayer)
        this.videoPlayer.srcObject = evt.streams[0];
    };

    this.pc.createOffer()
      .then((offer) => this.onLocalOffer(offer));
  }

  onLocalOffer(offer: any) {
    this.editOffer(offer);

    this.offerData = this.parseOffer(offer.sdp);
    this.pc?.setLocalDescription(offer);

    this.eventCallbacks("sending offer", 'onLocalOffer', {});

    fetch(new URL('whep', this.videoUrl) + window.location.search, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/sdp',
        Authorization: 'Basic YWRtaW46c3lKZkRrRXlReUZrTm9KdWZIWFk='
      },
      body: offer.sdp,
    })
      .then((res) => {
        if (res.status !== 201) {
          throw new Error('bad status code');
        }
        this.sessionUrl = new URL(res.headers.get('location') ?? '', this.videoUrl).toString();
        return res.text();
      })
      .then((sdp) => this.onRemoteAnswer(new RTCSessionDescription({
        type: 'answer',
        sdp,
      })))
      .catch((err) => {
        this.eventCallbacks('error: ' + err, 'onLocalOffer - catch',{});
        this.scheduleRestart();
      });
  }

  onConnectionState() {
    if (this.restartTimeout !== null) {
      return;
    }

    this.eventCallbacks("peer connection state:" + this.pc?.iceConnectionState, 'onConnectionState', { connected: this.pc?.iceConnectionState === 'connected'});

    switch (this.pc?.iceConnectionState) {
      case "disconnected":
        this.scheduleRestart();
    }
  }

  onRemoteAnswer(answer: any) {
    if (this.restartTimeout !== null) {
      return;
    }

    this.pc?.setRemoteDescription(answer);

    if (this.queuedCandidates.length !== 0) {
      this.sendLocalCandidates(this.queuedCandidates);
      this.queuedCandidates = [];
    }
  }

  onLocalCandidate(evt: any) {
    if (this.restartTimeout !== null) {
      return;
    }

    if (evt.candidate !== null) {
      if (this.sessionUrl === '') {
        this.queuedCandidates.push(evt.candidate);
      } else {
        this.sendLocalCandidates([evt.candidate])
      }
    }
  }

  sendLocalCandidates(candidates: any[]) {
    fetch(this.sessionUrl + window.location.search, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/trickle-ice-sdpfrag',
        'If-Match': '*',
        Authorization: 'Basic YWRtaW46c3lKZkRrRXlReUZrTm9KdWZIWFk='
      },
      body: this.generateSdpFragment(this.offerData, candidates),
    })
      .then((res) => {
        if (res.status !== 204) {
          throw new Error('bad status code');
        }
      })
      .catch((err) => {
        this.eventCallbacks('error: ' + err, 'sendLocalCandidates - catch', {err});
        this.scheduleRestart();
      });
  }

  scheduleRestart() {
    if(this.retryCounter !== undefined && this.retryCounter < 0)
      return;

    this.eventCallbacks('restarting cam peer connection, counter - ' + this.retryCounter ?? 0, 'scheduleRestart', {retryCounter: this.retryCounter});
    if (this.restartTimeout !== null) {
      return;
    }

    if (this.pc !== null) {
      this.pc.close();
      this.pc = null;
    }

    this.restartTimeout = window.setTimeout(() => {
      this.restartTimeout = null;
      this.start(this.videoPlayer);
      if(this.retryCounter !== undefined)
        this.retryCounter--;
    }, this.restartPause);

    if (this.sessionUrl) {
      fetch(this.sessionUrl, {
        method: 'DELETE',
        headers: {
          Authorization: 'Basic YWRtaW46c3lKZkRrRXlReUZrTm9KdWZIWFk='
        }
      })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error('bad status code');
          }
        })
        .catch((err) => {
          this.eventCallbacks('delete session error: ' + err, 'scheduleRestart - Catch', {err});
        });
    }
    this.sessionUrl = '';
    this.queuedCandidates = [];
  }

  start(videoPlayer: HTMLVideoElement) {
    this.videoPlayer = videoPlayer;

    if(this.videoUrl === 'NA')
      return;

    this.eventCallbacks("requesting ICE servers", 'START', {});

    fetch(new URL('whep', this.videoUrl) + window.location.search, {
      method: 'OPTIONS',
      headers: {
        Authorization: 'Basic YWRtaW46c3lKZkRrRXlReUZrTm9KdWZIWFk='
      }
    })
      .then((res) => this.onIceServers(res))
      .catch((err) => {
        this.eventCallbacks('error: ' + err, 'Start - Catch', {err});
        this.scheduleRestart();
      });
  }

  stop() {
    this.pc?.close();
  }
}
