export enum StorageKey{
  tokenStorageKey = 'b3ee528b-682f-4d63-9d41-6b45838ac11f',
  dropdowns = 'dropdowns',
  dropdownSelections = 'dropdown-selections',
  gallerySelections = 'gallerySelections',
  selectedCam = 'selectedCam',
  cameras = 'cameras',
  centers = 'centers',
  recordingDays = 'recordingDays'
}

export const videoBoxSizes = [
  {h: 225, w: 400},
  {h: 252, w: 448},
  {h: 306, w: 544},
  {h: 333, w: 592}
]

export const generateQueryString = (propertyBag: any, existingParams: any) => {
  let queryStrings: string[] = [];
  Object.keys(propertyBag).forEach(e => {
    const val = ((propertyBag as any)[e] ?? '').toString();
    if(val.toString().length > 0)
      queryStrings.push(`${e}=${val}`);

  })

  // Don't Hijack existing query params
  const qp = existingParams;
  Object.keys(qp).forEach(e => {
    if(queryStrings.findIndex(m => m.includes(`${e}=`)) === -1){
      queryStrings.push(`${e}=${qp[e]}`);
    }
  })

  const new_url = `${location.origin}${location.pathname}?${queryStrings.join('&')}`;
  history.pushState(propertyBag, '', new_url);
}
