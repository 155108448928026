import {Inject, Injectable} from '@angular/core';
import * as signalR from "@microsoft/signalr"
import {LogLevel} from "@microsoft/signalr"
import {lastValueFrom} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {API_BASE_URL, IFaceMatchResults} from "./openApiClients";

@Injectable({
  providedIn: 'root'
})
export class RsldcApiService {
  hubConnection: signalR.HubConnection | undefined
  apiUrl: string;
  constructor(private http: HttpClient, @Inject(API_BASE_URL) baseUrl: string) {
    this.apiUrl = baseUrl;
  }

  async startConnection(){
    this.hubConnection = new signalR.HubConnectionBuilder()
                          .withUrl(this.apiUrl + '/cogn-service/subscribe')
                          .withAutomaticReconnect()
                          .configureLogging(LogLevel.Debug)
                          .build();

    await this.hubConnection.start();
    this.subscribeMethods();
  }

  private subscribeMethods(){
    this.hubConnection?.on('generalCommunication', this.receiveGeneralCommunication)
  }

  async receiveGeneralCommunication(...args: any[]){
    console.log(args);
  }

  public async FindMatchByFace(centerId: number, batchId: string, imageBase64: string, mock: boolean = false, thresh: number = 80){
    return await lastValueFrom(this.http.post<IFaceMatchResults>(
      `${this.apiUrl}/presence/find-match?centerId=${centerId}&batchId=${batchId}&mock=${mock}&th=${thresh}`
      , imageBase64));
  }



}
