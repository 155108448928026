import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {lastValueFrom} from 'rxjs';
import {icons, strip_text} from "../../common/helpers";
import {Camera, CamHealthResponse, CamHealthResultType, StreamClient} from "../../schematics/apiCore";
import WebRtcClient from "../../common/WebRtcClient";
import {faFileVideo, faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import {Router} from "@angular/router";
import { Batch } from 'src/app/schematics/openApiClients';
import dayjs from "dayjs";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy{
  @Input() Camera: Camera = {} as Camera;
  @Input() Batch: Batch[] = [];
  @Input() height = 281;
  @Input() width = 500;
  @Input() CommandCenterMode = false;
  @Input() Selected: boolean = false;
  @Input() ShowRecordingButton = false;
  @Input() RecordingPlaybackMode: boolean = false;
  retryCounter = 10;
  showBatchDetails: boolean = false;
  camHealth?: CamHealthResponse;
  healthMessage?: string;
  protected readonly icons = icons;

  webRtcClient?: WebRtcClient;

  @ViewChild('video')
  videoPlayer!: ElementRef<HTMLVideoElement>
  constructor(private router: Router, private streamClient: StreamClient) {

  }

  ngOnDestroy(): void {
    this.webRtcClient?.stop();
  }

  Start(){
    (this.webRtcClient ??
      (this.Camera.webRtcUrl ? this.getWebRtcClient(this.Camera.webRtcUrl) : undefined))?.start(this.videoPlayer.nativeElement);
  }

  Stop(){
    this.webRtcClient?.stop();
  }

  VideoRefElement(){
    return this.videoPlayer.nativeElement;
  }

  get boxSize(){
    return {
      width: this.width + 'px',
      height: (this.height + 30) + 'px'
    }
  }

  get videoSize(){
    return {
      width: this.width + 'px',
      height: this.height + 'px',
      'margin-top': '26px'
    }
  }

  async ngOnInit() {
    if(environment.unloadCameras)
      this.Camera.id = undefined;

    if(this.Camera.id){
      const videoUrl = this.Camera.webRtcUrl;
      if(this.Camera.archived){
        this.Camera.online = false;
        this.Camera.url = "NA";
      }

      this.webRtcClient = videoUrl ? this.getWebRtcClient(videoUrl) : undefined;
    }
  }

  getWebRtcClient(videoUrl: string): WebRtcClient {
    return new WebRtcClient(videoUrl, this.retryCounter, async (message: string, type: string, viewBag: any) => {
      if (type === "onConnectionState" && !!viewBag.connected) {
        this.retryCounter = 10;
        this.webRtcClient?.setRetryCount(this.retryCounter);
      }
      console.log(`[${this.Camera.id}] [${type.toUpperCase()}] ${message}`, viewBag, 'retry', this.webRtcClient?.retryCounter)
      if (this.webRtcClient?.retryCounter && this.webRtcClient?.retryCounter <= 0)
        this.Camera.online = false;
    });
  }

  get camName(): string {
    return strip_text(this.Camera?.name, 30);
  }

  get offlineFullStyle() {
    return {
      width: '100%',
      height: '100%',
      top: '30px',
      left: 0,
      transform: 'unset'
    }
  }

  async ngAfterViewInit() {
    this.retryCounter = 10;
    if(this.Camera?.id && !this.Camera.archived)
    {
      const videoPlayer = this.videoPlayer.nativeElement;
      if(videoPlayer != null){
        videoPlayer.controls = true;
        videoPlayer.muted = true;
        videoPlayer.autoplay = true;
        videoPlayer.playsInline = true;
        this.Camera.online = true;

        this.camHealth = await lastValueFrom(this.streamClient.checkCamHealth(this.Camera.id));

        if (this.camHealth.status === CamHealthResultType.Online) {
          this.webRtcClient?.stop();
          this.webRtcClient?.start(videoPlayer);
        } else this.Camera.online = false;

      }
    }
  }

  async openRecordings() {

    await this.router.navigate(['/recordings'], {
      queryParams: {
        camId: this.Camera.id,
        centerId: this.Camera.trainingCenterId,
        returnUrl: encodeURI(document.location.href)
      },
    })

  }
  timeFormat(str: any) {
    console.log(str.toString());
    return dayjs(dayjs().format('YYYY-MM-DD ') + str.toString()).format('hh:mm A');
  }
}
