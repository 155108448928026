<!-- Toolbar -->
<div class="toolbar" role="banner">
  <img
    width="40"
    alt="Cognota Logo"
    src="../assets/cognota-logo-only.png" height="39"/>
  <span><a href="https://cognotaindia.com" target="_blank" class="company_name">Cognota India Services</a></span>
  <div class="spacer"></div>
  @if(!isPublicMode){
    <div class="select is-small is-info mr-2">
      <select class="server-selection-list" (change)="on_active_inactive_selected()" [(ngModel)]="active_inactive_selected">
        <option *ngFor="let v of active_inactive; let i = index" [value]="v">{{v}}</option>
      </select>
    </div>
  }
  <div class="select is-small is-info mr-2">
    <select class="server-selection-list" (change)="server_selected()" [(ngModel)]="server">
      <option *ngFor="let server of core_servers; let i = index" [value]="server">SERVER - {{i + 1}}</option>
    </select>
  </div>
  <button class="button is-small is-info is-radiusless has-shadow mr-3" *ngIf="isAuthenticated" (click)="logout()">Logout</button>
  <a href="https://cognota.freshdesk.com" class="button is-small is-radiusless has-shadow mr-3" target="_blank">Support</a>
</div>
<div class="content is-paddingless" style="max-width: unset; align-items: stretch;margin-top: 60px" role="main" >
  <router-outlet *ngIf="!isIframe"></router-outlet>
  <div class="mb-6"></div>
  <div class="mb-6"></div>
  <div class="mb-6"></div>
  <svg id="clouds" xmlns="http://www.w3.org/2000/svg" width="2611.084" height="485.677" viewBox="0 0 2611.084 485.677">
    <title>Gray Clouds Background</title>
    <path id="Path_39" data-name="Path 39" d="M2379.709,863.793c10-93-77-171-168-149-52-114-225-105-264,15-75,3-140,59-152,133-30,2.83-66.725,9.829-93.5,26.25-26.771-16.421-63.5-23.42-93.5-26.25-12-74-77-130-152-133-39-120-212-129-264-15-54.084-13.075-106.753,9.173-138.488,48.9-31.734-39.726-84.4-61.974-138.487-48.9-52-114-225-105-264,15a162.027,162.027,0,0,0-103.147,43.044c-30.633-45.365-87.1-72.091-145.206-58.044-52-114-225-105-264,15-75,3-140,59-152,133-53,5-127,23-130,83-2,42,35,72,70,86,49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33,61.112,8.015,113.854-5.72,150.492-29.764a165.62,165.62,0,0,0,110.861-3.236c47,94,178,113,251,33,31.385,4.116,60.563,2.495,86.487-3.311,25.924,5.806,55.1,7.427,86.488,3.311,73,80,204,61,251-33a165.625,165.625,0,0,0,120,0c51,13,108,15,157-5a147.188,147.188,0,0,0,33.5-18.694,147.217,147.217,0,0,0,33.5,18.694c49,20,106,18,157,5a165.625,165.625,0,0,0,120,0c47,94,178,113,251,33C2446.709,1093.793,2554.709,922.793,2379.709,863.793Z" transform="translate(142.69 -634.312)" fill="#eee"/>
  </svg>
</div>
<footer class="footer p-3" style="background-color: transparent;position: fixed;bottom: 0;width: 100%;z-index: -10;">
  <div class="content has-text-centered">
    <p>
      <strong>Cognota Services India Private Limited</strong><br />
      <span>sales&#64;cognotaindia.com</span><br />
      <span><a href="tel:+917230025073" class="is-block is-bold">+91-7230025073</a></span>

    </p>
  </div>
</footer>
